import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  savedMyThaiCategory: {},
  successResponse: {},
  formStatus: {
    status: false,
    message: ""
  },
  isLoading: false,
  isError: false,
  error: false
};
const crmLostFoundSlice = createSlice({
  name: "crmLostFoundService",
  initialState,
  reducers: {
    crmLostFoundRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    crmLostFoundSuccess: (state, action) => {
      state.savedMyThaiCategory = action.payload;
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successResponse = action.payload;
    },
    crmLostFoundFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    clearFormSuccessResponse: state => {
      state.formStatus.message = "";
      state.formStatus.status = false;
    },
    crmLostFoundInputSave: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    crmLostFoundClear: state => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
      state.savedMyThaiCategory = null;
      state.successData = null;
      state.formStatus.message = "";
      state.formStatus.status = false;
    },
    submitFormSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successData = action.payload;
      if (state.successData.message === "Case Created") {
        state.formStatus.message = "Case Created";
        state.formStatus.status = true;
      }
      // state.codeStatus = "success";
    }
  }
});
export const {
  crmLostFoundRequest,
  crmLostFoundSuccess,
  crmLostFoundFailure,
  crmLostFoundInputSave,
  crmLostFoundClear,
  submitFormSuccess,
  clearFormSuccessResponse
} = crmLostFoundSlice.actions;
export default crmLostFoundSlice.reducer;